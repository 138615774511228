
import { defineComponent, } from 'vue'
import img1 from "../assets/animation/more/1.png"
import img2 from "../assets/animation/more/2.png"
import img3 from "../assets/animation/more/3.png"
  export default defineComponent({
    components: {},
    methods: {
      gotoPay () {
        this.$router.push('/payAli')
      }
    },
    data () {
      return {
        data: [
          {
            img: img1,
            title: '什么是骨骼动画',
            description: '骨骼动画（Skeletal Animation），也称为骨架动画或骨架绑定动画，是一种在三维计算机图形学中常用的动画技术。它通过使用骨骼（或骨架）和皮肤（或网格）来创建和控制复杂模型的动画。以下是骨骼动画的一些关键特点：骨骼结构：骨骼动画中的“骨骼”是一系列相互连接的关节，它们定义了模型可以移动的方式。这些关节可以旋转、缩放和移动，从而影响附着在其上的模型部分。'
          },
          {
            img: img2,
            title: '什么是IK',
            description: '逆向动力学（Inverse Kinematics，简称IK）是一种在机器人学、计算机图形学和动画制作等领域中常用的技术。它的核心思想是确定一组适当的关节角度，使得机械臂的末端（如手或脚）能够尽可能平稳、快速、准确地移动到所需位置。与传统的正向动力学（Forward Kinematics，FK）不同，'
          },
          {
            img: img3,
            title: '什么是FFD',
            description: 'FFD（Free-Form Deformation，自由形式变形）是一种在计算机图形学和三维建模中常用的技术，它允许用户通过控制一个嵌入在模型内部或周围的网格来对模型进行变形。以下是FFD的一些关键特点和应用：变形原理：FFD算法的核心思想是将3D模型嵌入到一个更高维的空间中，并通过控制这个空间的网格点来形变模型。具体步骤包括定义控制网格、参数化模型点和计算形变。'
          },
        ]
      }
    }
})
  