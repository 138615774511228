<template>
  <div class="pay-page">
    <h2 class="pay-title">扫码付款</h2>
    <div class="pay-content">
      <img class= 'pay-qcode' src="../assets/alipay.jpg" />
    </div>>
  </div>
  </template>
  <script lang="ts">
  import { Card } from 'ant-design-vue'
import { defineComponent, } from 'vue'
  export default defineComponent({
})
  </script>
  <style>
  .pay-page {
    height: 80vh;
    background-color: #fcfcfc;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .pay-title {
    color: #000;
    text-align: center;
    padding-top: 20px;
  }
  .pay-content {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
  }
  .slash {
    text-decoration: line-through;
  }
  .pay-qcode {
    width: 20%;
    margin: auto;
    text-align: center;
    display: flex;
  }
  .price {
    color: red;
    font-size: 20px;
  }
  </style>
  