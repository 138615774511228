<template>
  <div class="contact-page">
    <h2 class="contact-title">联系我们</h2>
    <div class="contact-text">公司名称：北京哪吒互娱科技有限公司</div>
    <div class="contact-text">公司地址：北京市顺义区绿港家园一区9号楼1层140号896</div>
    <div class="contact-text">邮箱：nezhahuyu@126.com</div>
    <img class="contact-qq" src="../assets/qqgroup.jpg" />
  </div>
  </template>
  <script lang="ts">
  import { defineComponent, } from 'vue'
  export default defineComponent({
  })
  </script>
  <style>
  .contact-page {
    height: 80vh;
    background-color: #fcfcfc;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .contact-title {
    color: #000;
    text-align: center;
    padding-top: 20px;
  }
  .contact-text {
    padding-left:  100px;
    padding-right: 100px;
    color: #000;
    text-align: center;
    padding-top: 20px;
  }
  .contact-qq {
    width: 15%;
    margin: auto;
    text-align: center;
    display: flex;
  }
  </style>
  